import { useConfigStore } from '@/stores/config'
export default {
  computed: {
    showVAT() {
      const configStore = useConfigStore()
      return configStore.getShowVAT
    },
  },
  methods: {
    localePrice(value, showVAT = false, VAT = false) {
      const price = Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
      })
      if (showVAT) {
        if (VAT) return `${price} ${this.$t('actions.VAT')}`
        return `${price} ${this.$t('actions.noVAT')}`
      }
      return price
    },
  },
}
